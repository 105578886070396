import React from "react";
import {
  Container,
  useMediaQuery,
  SwipeableDrawer,
  Button,
  List,
  ListItem,
  ListItemText,
  Box,
  Paper,
  Typography,
  IconButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import MenuIcon from "@mui/icons-material/Menu";
import clsx from "clsx";
import {
  logo,
  twitterLogo,
  telegramLogo,
  redditLogo,
  facebookLogo,
  instagramLogo,
  discordLogo,
} from "./SmallComponents/Images";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
    alignItems: "center",
  },
  paper: {
    background: "#181A28 !important",
  },
  hover: {
    "&:hover": {
      color: "#FFB800",
    },
  },
});

const headerText = [
  {
    name: "HOME",
    link: "https://goldeninutoken.org/",
  },
  {
    name: "GOLDEN INUVERSE",
    link: "https://goldeninuverse.com/",
  },
  {
    name: "WHITEPAPER",
    link: "https://docs.goldeninutoken.org/golden-ecosystem/",
  },
];
const socialArray = [
  { img: twitterLogo },
  { img: telegramLogo },
  { img: redditLogo },
  { img: instagramLogo },
  { img: facebookLogo },
  { img: discordLogo },
];

export default function Header() {
  // const { account, connect, disconnect } = useContext(AppContext);
  const classes = useStyles();
  const [state, setState] = React.useState({
    left: false,
  });
  const matches1 = useMediaQuery("(max-width:1279px)");
  const matches = useMediaQuery("(max-width:1032px)");

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Box display="flex" justifyContent="center">
        <img width="120px" src={logo} alt="" />
      </Box>
      <List>
        {headerText.map(({ name, link }) => (
          <ListItem
            component="a"
            href={link}
            rel="noreferrer"
            target="_blank"
            button
            style={{
              justifyContent: "center",
              textDecoration: "none",
            }}
            key={name}
          >
            <ListItemText
              style={{
                textTransform: "capitalize",
                textAlign: "center",
                textDecoration: "none",
                cursor: "pointer",
                color: "#4B4F6D",
              }}
              primary={name}
            />
          </ListItem>
        ))}
      </List>
      <Box mb={1} display="flex" justifyContent="center" alignItems="center">
        {socialArray.map(({ img }) => (
          <IconButton
            href="#"
            target="_blank"
            key={img}
            sx={{ padding: "3px" }}
          >
            <Box component="img" height="30px" alt="" src={img} />
          </IconButton>
        ))}
      </Box>
    </div>
  );
  return (
    <>
      <Box
        sx={{
          background: "transparent",
        }}
        height="92px"
        width="100%"
        py={1}
      >
        <Container maxWidth="xl">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{ backgroundColor: "#181A28", borderRadius: "8px", px: "10px" }}
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ cursor: "pointer" }}
            >
              <img width="60px" src={logo} alt="" />
              <Typography variant="h2" lineHeight="24px">
                The Golden <span style={{ color: "#FF4E54" }}>Treasury </span>
              </Typography>
            </Box>
            <Box
              display="flex"
              justifyContent={matches1 ? "end" : "space-between"}
              alignItems="center"
            >
              {matches ? (
                <>
                  {["left"].map((anchor) => (
                    <React.Fragment key={anchor}>
                      <Button
                        onClick={toggleDrawer(anchor, true)}
                        style={{ zIndex: 1 }}
                      >
                        <MenuIcon
                          style={{
                            fontSize: "38px",
                            cursor: "pointer",
                            color: "#ffff",
                          }}
                        />
                      </Button>
                      <Paper>
                        <SwipeableDrawer
                          classes={{ paper: classes.paper }}
                          anchor={anchor}
                          open={state[anchor]}
                          onClose={toggleDrawer(anchor, false)}
                          onOpen={toggleDrawer(anchor, true)}
                        >
                          {list(anchor)}
                        </SwipeableDrawer>
                      </Paper>
                    </React.Fragment>
                  ))}{" "}
                </>
              ) : (
                <>
                  {headerText?.map(({ name, link }) => (
                    <a
                      key={name}
                      href={link}
                      rel="noreferrer"
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      <Typography variant="h5" mr={6}>
                        {name}
                      </Typography>
                    </a>
                  ))}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {socialArray.map(({ img }) => (
                      <IconButton
                        href="#"
                        target="_blank"
                        key={img}
                        sx={{ padding: "4px" }}
                      >
                        <Box component="img" height="30px" alt="" src={img} />
                      </IconButton>
                    ))}
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
}
