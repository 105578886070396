import { responsiveFontSizes, createTheme } from "@mui/material/styles";

let theme = createTheme({
  palette: {
    primary: {
      main: "#537FEF",
    },
    secondary: {
      main: "#D6EDF6",
    },
    text: {
      textColortheme: "#fff",
      gray: "#7a7a7a ",
    },
    background: {
      // basic: "#13141E",
      // secondary: "#fff",
      // darkBlue: " #131923",
    },
  },
  typography: {
    fontFamily: ["Montserrat", "Open Sans", "sans-serif"].join(","),

    h1: {
      fontSize: "55px",
      fontWeight: "700",
      background: "transparent",
      backgroundImage:
        "linear-gradient(228.44deg,  #9143F5 4.42%,#24B7FB  70.89%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      fontFamily: "Montserrat",
    },
    h2: {
      fontSize: "20px",
      fontWeight: "700",
      fontFamily: "Montserrat",
      color: "#fff",
    },

    h3: {
      fontSize: "15px",
      fontWeight: "400",
      fontFamily: "Montserrat",
      color: "#fff",
    },
    h4: {
      fontSize: "45px",
      fontWeight: "800",
      fontFamily: "Montserrat",
      background: "transparent",
      backgroundImage:
        "linear-gradient(228.44deg,  #9143F5 4.42%,#24B7FB  70.89%)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
    },
    h5: {
      fontSize: "15px",
      fontWeight: "700",
      fontFamily: "Montserrat",
      color: "#4B4F6D",
    },
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: "btn1" },
          style: {
            background: "#537FEF",
            color: "#ffff",
            borderRadius: "8px",
            fontStyle: "normal",
            fontFamily: "Montserrat",
            fontWeight: "700",
            fontSize: "20px",
            "&:hover": {
              background: "#537FEF",
            },
          },
        },
        {
          props: { variant: "btn2" },
          style: {
            background: "#EEB312",
            color: "#ffff",
            borderRadius: "8px",
            fontStyle: "normal",
            fontFamily: "Montserrat",
            fontWeight: "700",
            fontSize: "20px",
            "&:hover": {
              background: "#EEB312",
            },
          },
        },
      ],
    },
  },
});

theme = responsiveFontSizes(theme);
export { theme };
