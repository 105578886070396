import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import {
  StyledGraphBox,
  StyledGraphNumberBox,
} from "../../components/SmallComponents/AppComponents";
import Chart from "../../components/SmallComponents/chart";
import { treasuryReadFunction } from "../../ConnectivityAssets/hooks";
import { formatUnits } from "viem";

export const dummyDays = [0, 1, 2, 3, 4, 5, 6, 7, 8];
export const dummyValues = [0, 10, 20, 30, 40, 50, 60, 70, 80];

function GridOneGraph() {
  const [chartOneDays, setChartOneDays] = useState([]);
  const [chartOneValues, setChartOneValues] = useState([]);
  const [lastDayBurn, setLastDayBurn] = useState(0);
  const [lastSevenDayBurn, setLastSevenDayBurn] = useState(0);
  const [lastThirtyDayBurn, setLastThirtDayBurn] = useState(0);
  useEffect(() => {
    (async () => {
      try {
        let currentDay = await treasuryReadFunction("currentDay");
        currentDay = +currentDay.toString();
        const lastDay = currentDay === 0 ? 0 : currentDay - 1;
        let sevenDayData = 0;
        let thirtyDayData = 0;

        const checkOneDayBurn = await treasuryReadFunction("dailyBurn", [
          lastDay,
        ]);
        setLastDayBurn(
          parseFloat(formatUnits(checkOneDayBurn.toString(), 9)).toFixed(2)
        );
        for (let i = currentDay - 1; i >= currentDay - 7; i--) {
          if (i >= 0) {
            const checkBurn = await treasuryReadFunction("dailyBurn", [i]);
            sevenDayData =
              sevenDayData + parseFloat(formatUnits(checkBurn.toString(), 9));
          }
        }
        setLastSevenDayBurn(sevenDayData.toFixed(2));

        for (let i = currentDay - 1; i >= currentDay - 30; i--) {
          if (i >= 0) {
            const checkBurn = await treasuryReadFunction("dailyBurn", [i]);
            thirtyDayData =
              thirtyDayData + parseFloat(formatUnits(checkBurn.toString(), 9));
          }
        }
        setLastThirtDayBurn(thirtyDayData.toFixed(2));

        let days = [];
        let values = [];
        for (let i = 0; i <= currentDay; i++) {
          days.push(i);
          const dailyBurn = await treasuryReadFunction("dailyBurn", [i]);
          values.push(
            parseFloat(formatUnits(dailyBurn.toString(), 9)).toFixed(2)
          );
        }

        // const days = [currentDay];
        // const values = [
        //   parseFloat(formatUnits(dailyBurn.toString(), 9)).toFixed(2),
        // ];
        setChartOneDays([...days]);
        setChartOneValues([...values]);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);
  return (
    <>
      <Box sx={{ backgroundColor: "#0A0C1B", px: 2, pt: 2 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "center", sm: "space-between" },
            alignItems: "center",
            flexDirection: { xs: "column", sm: "row" },
            borderBottom: "1px solid #4B4F6D",
            pb: "20px",
          }}
        >
          <StyledGraphBox color="#FF4E54" title="Daily Burn" />
          <StyledGraphNumberBox
            oneDayBurn={lastDayBurn}
            sevenDayBurn={lastSevenDayBurn}
            thirtyDayData={lastThirtyDayBurn}
          />
        </Box>
        <Box
          sx={{
            height: "300px",
            width: "100%",
          }}
        >
          <Chart
            lineColor="#FF4E54"
            axisColorOne="rgba(255, 78, 84, 0.35)"
            axisColorTwo="rgba(10, 12, 27, 0)"
            days={chartOneDays}
            values={chartOneValues}
          />
        </Box>
      </Box>
      <Box sx={{ backgroundColor: "#0A0C1B", px: 2, pt: 2, my: 2 }}>
        <Box
          sx={{
            borderBottom: "1px solid #4B4F6D",
            mt: 2,
            pb: { xs: "0", sm: "20px" },
          }}
        >
          <StyledGraphBox color="#537FEF" title="Circulating Supply" />
        </Box>
        <Box
          sx={{
            height: "300px",
          }}
        >
          <Chart
            lineColor="#537FEF"
            axisColorOne="rgba(83, 127, 239, 0.35)"
            axisColorTwo="rgba(10, 12, 27, 0)"
            days={dummyDays}
            values={dummyValues}
          />
        </Box>
      </Box>
    </>
  );
}

export default GridOneGraph;
