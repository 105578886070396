import tokenAbi from "./tokenAbi.json";
import treasuryAbi from "./treasuryAbi.json";
import bscTreasuryAbi from "./bscTreasuryAbi.json";
import {
  tokenAddress,
  treasuryAddress,
  bscTreasuryAddress,
} from "./environment";

import { readContract, writeContract } from "wagmi/actions";
import { waitForTransaction } from "@wagmi/core";

export const tokenReadFunction = async (functionName, args) => {
  const data = await readContract({
    address: tokenAddress,
    abi: tokenAbi,
    functionName,
    args,
  });
  return data;
};

export const treasuryReadFunction = async (functionName, args) => {
  const data = await readContract({
    address: treasuryAddress,
    abi: treasuryAbi,
    chainId: 1,
    functionName,
    args,
  });
  return data;
};
export const bscTreasuryReadFunction = async (functionName, args) => {
  const data = await readContract({
    chainId: 56,
    address: bscTreasuryAddress,
    abi: bscTreasuryAbi,
    functionName,
    args,
  });
  return data;
};

/// write functions
export const tokenWriteFunction = async (functionName, args) => {
  const { hash } = await writeContract({
    address: tokenAddress,
    abi: tokenAbi,
    functionName,
    args,
  });
  const receipt = await waitForTransaction({ hash });
  return receipt;
};

export const treasuryWriteFunction = async (functionName, args, value) => {
  const { hash } = await writeContract({
    address: treasuryAddress,
    abi: treasuryAbi,
    functionName,
    chainId: 1,
    args,
    value,
  });
  const receipt = await waitForTransaction({ hash });
  return receipt;
};

export const bscTreasuryWriteFunction = async (functionName, args, value) => {
  const { hash } = await writeContract({
    address: bscTreasuryAddress,
    abi: bscTreasuryAbi,
    chainId: 56,
    functionName,
    args,
    value,
  });
  const receipt = await waitForTransaction({ hash });
  return receipt;
};
