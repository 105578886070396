import { Box, Grid, Typography } from "@mui/material";
import React from "react";

function HoldersBox() {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Box sx={{ backgroundColor: "#0A0C1B", py: 1, borderRadius: "8px" }}>
            <Typography
              variant="h5"
              sx={{
                fontSize: "10px !important",
                textAlign: "center",
              }}
            >
              HOLDERS
              <br />
              <Box
                component="span"
                sx={{ fontSize: { xs: "18px", sm: "24px" }, color: "#fff" }}
              >
                N/A
              </Box>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box sx={{ backgroundColor: "#0A0C1B", py: 1, borderRadius: "8px" }}>
            <Typography
              variant="h5"
              sx={{
                fontSize: "10px !important",
                textAlign: "center",
              }}
            >
              VOLUME
              <br />
              <Box
                component="span"
                sx={{ fontSize: { xs: "18px", sm: "24px" }, color: "#fff" }}
              >
                N/A
              </Box>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box sx={{ backgroundColor: "#0A0C1B", py: 1, borderRadius: "8px" }}>
            <Typography
              variant="h5"
              sx={{
                fontSize: "10px !important",
                textAlign: "center",
              }}
            >
              TILL SHIBA
              <br />
              <Box
                component="span"
                sx={{ fontSize: { xs: "18px", sm: "24px" }, color: "#fff" }}
              >
                N/A
              </Box>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default HoldersBox;
