import React from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { ethLogo, bscLogo } from "../components/SmallComponents/Images";
import GridOneGraph from "./graphsSection/GridOneGraph";
import GridTwoGraph from "./graphsSection/GridTwoGraph";
import HoldersBox from "./HoldersBox";
// import { AppContext } from "../utils";
// import { useWeb3Modal } from "@web3modal/react";

function HeroSection() {
  // const { account } = useContext(AppContext);

  // const { open } = useWeb3Modal();
  const matches = useMediaQuery("(max-width:1032px)");

  return (
    <>
      <Box mb={4}>
        <Container maxWidth="xl">
          <Grid container spacing={2}>
            <Grid item xs={12} md={matches ? 12 : 6}>
              {" "}
              <Box
                sx={{
                  backgroundColor: "#181A28",
                  borderRadius: "8px",
                  p: 2,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: { xs: "column", sm: "row" },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: { xs: "100%", sm: "250px" },
                      justifyContent: { xs: "center", sm: "left" },
                      mb: { xs: 1.5, sm: 0 },
                      py: "8px",
                      px: "6px",
                      borderRadius: "8px",
                      backgroundColor: "#0A0C1B",
                    }}
                  >
                    <Box component="img" alt="" src={ethLogo} width={"45px"} />
                    <Typography variant="h2">
                      {" "}
                      GOLDEN <span style={{ color: "#537FEF" }}>ETH</span>
                    </Typography>
                  </Box>
                  <Button
                    component="a"
                    href="https://flooz.xyz/trade/0xD87996Ff3D06858BFC20989aEF50cc5FCD4d84ca?network=eth"
                    target="_blank"
                    variant="btn1"
                    sx={{
                      width: { xs: "100%", sm: "250px" },
                      py: "4px",
                      px: "6px",
                    }}
                  >
                    buy golden
                  </Button>
                  {/* {account ? (
                    <Button
                      component="a"
                      href="https://flooz.xyz/trade/0xD87996Ff3D06858BFC20989aEF50cc5FCD4d84ca?network=eth"
                      target="_blank"
                      variant="btn1"
                      sx={{
                        width: { xs: "100%", sm: "250px" },
                        py: "4px",
                        px: "6px",
                      }}
                    >
                      buy golden
                    </Button>
                  ) : (
                    <Button
                      variant="btn1"
                      sx={{
                        width: { xs: "100%", sm: "250px" },
                        py: "4px",
                        px: "6px",
                      }}
                      onClick={async () => {
                        await open();
                      }}
                    >
                      Connect
                    </Button>
                  )} */}
                </Box>
                <Box mt={3}>
                  <GridOneGraph />
                </Box>
                <Box mt={2}>
                  <HoldersBox />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={matches ? 12 : 6}>
              {" "}
              <Box
                sx={{
                  backgroundColor: "#181A28",
                  borderRadius: "8px",
                  p: 2,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: { xs: "column", sm: "row" },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: { xs: "100%", sm: "250px" },
                      justifyContent: { xs: "center", sm: "left" },
                      mb: { xs: 1.5, sm: 0 },
                      py: "8px",
                      px: "6px",
                      borderRadius: "8px",
                      backgroundColor: "#0A0C1B",
                    }}
                  >
                    <Box
                      component="img"
                      alt=""
                      src={bscLogo}
                      width={"28px"}
                      sx={{ ml: { xs: "0", sm: "10px" } }}
                    />
                    <Typography variant="h2" ml="6px">
                      {" "}
                      GOLDEN <span style={{ color: "#EEB312" }}>BSC</span>
                    </Typography>
                  </Box>
                  <Button
                    component="a"
                    href="https://flooz.xyz/trade/0x7D4984490c4C68F8eAD9dddcA6d04C514Ef77324?network=bsc"
                    target="_blank"
                    variant="btn2"
                    sx={{
                      width: { xs: "100%", sm: "250px" },
                      py: "4px",
                      px: "6px",
                    }}
                  >
                    buy golden
                  </Button>
                  {/* {account ? (
                    <Button
                      component="a"
                      href="https://flooz.xyz/trade/0x7D4984490c4C68F8eAD9dddcA6d04C514Ef77324?network=bsc"
                      target="_blank"
                      variant="btn2"
                      sx={{
                        width: { xs: "100%", sm: "250px" },
                        py: "4px",
                        px: "6px",
                      }}
                    >
                      buy golden
                    </Button>
                  ) : (
                    <Button
                      variant="btn2"
                      sx={{
                        width: { xs: "100%", sm: "250px" },
                        py: "4px",
                        px: "6px",
                      }}
                      onClick={async () => {
                        await open();
                      }}
                    >
                      Connect
                    </Button>
                  )} */}
                </Box>
                <Box mt="30px">
                  <GridTwoGraph />
                </Box>
                <Box mt={2}>
                  <HoldersBox />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default HeroSection;
