import React, { useState } from "react";
import ReactECharts from "echarts-for-react";
import { graphic } from "echarts";

function Chart({ lineColor, axisColorOne, axisColorTwo, days, values }) {
  // const [data, setData] = useState([
  //   {
  //     name: "Daily Burn",
  //     value: 50,
  //   },
  //   {
  //     name: "Weekly Burn",
  //     value: 100,
  //   },
  //   {
  //     name: "Monthly Burn",
  //     value: 150,
  //   },
  //   {
  //     name: "Monthly Burn",
  //     value: 200,
  //   },
  // ]);

  const option = {
    backgroundColor: "#0A0C1B",
    xAxis: {
      // type: "name",
      axisLine: {
        lineStyle: {
          color: "#4B4F6D",
        },
      },
      splitLine: {
        show: false,
        lineStyle: {
          color: "#4B4F6D",
        },
      },
      data: days,
    },
    yAxis: {
      type: "value",
      axisLine: {
        lineStyle: {
          color: "#4B4F6D",
        },
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: "#4B4F6D",
        },
      },
      min: function (value) {
        return value.min;
      },
      // min: 0,
      // max: 150,
      max: function (value) {
        return value.max;
      },

      axisLabel: {
        formatter: function (value) {
          return value;
          // return value + "k";
        },
      },
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "10%",
      // top: "15%",
      containLabel: true,
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "line",
        lineStyle: {
          color: "#4B4F6D",
        },
      },
    },
    // dataZoom: [
    //   {
    //     type: "inside",
    //   },
    // ],
    series: [
      {
        name: "Price",
        type: "line",
        stack: "Total",
        emphasis: {
          focus: "series",
        },
        color: lineColor,
        areaStyle: {
          color: new graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: axisColorOne,
            },
            {
              offset: 1,
              color: axisColorTwo,
            },
          ]),
        },
        data: values,
      },
    ],
  };

  return (
    <>
      <ReactECharts
        style={{
          width: "100%",
          height: "100%",
        }}
        option={option}
      />
    </>
  );
}

export default Chart;
