export const tokenAddress = "0x55ECAA082e9B8A2fcF742E3A79af906bD4525B23";

///testnet contract
// export const treasuryAddress = "0x5dF8E1C0f6CFF7cAac22566baA4A8892626D02bA";
// export const bscTreasuryAddress = "0x3e4194ae99e126757bEBAE3A7025a91e36f118e5";

/// mainnet contract

export const treasuryAddress = "0xb1DB5E15858ADfF1F3F21a0C07BE0349859da260";
export const bscTreasuryAddress = "0x958e500b84344DF6198f36A1F99A5933ae133320";
