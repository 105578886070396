import { Alert, Box, Snackbar, Typography } from "@mui/material";
import { Button } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

export function ToastNotify({ alertState, setAlertState }) {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={alertState.open}
      autoHideDuration={10000}
      key={"top center"}
      onClose={() => setAlertState({ ...alertState, open: false })}
    >
      <Alert
        onClose={() => setAlertState({ ...alertState, open: false })}
        severity={alertState.severity}
      >
        {alertState.message}
      </Alert>
    </Snackbar>
  );
}

export function StyledButton({ children, ...props }) {
  return (
    <>
      <Button
        {...props}
        sx={{
          color: "#ffffff",
          background:
            "radial-gradient(145.24% 943.2% at 7.91% 50%, #1c85fe91 0%, #be4bc870 40%)",
          fontSize: "18px",
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          textTransform: "capitalize",
          //   fontFamily: "Josefin Sans",
          borderRadius: "12px",
          width: props.width,
          "&.Mui-disabled": {
            color: "#979EA7",
          },
          "&:hover": {
            background:
              "radial-gradient(145.24% 943.2% at 7.91% 50%, #be4bc870 0%, #1c85fe91 40%)",
          },
        }}
      >
        {children}
      </Button>
    </>
  );
}

export function StyledText({ children, ...props }) {
  return (
    <>
      <Box
        {...props}
        sx={{
          color: "#000000",
          fontSize: "18px",
          //   fontFamily: "Josefin Sans",
          fontWeight: "500",
          mr: props.mr,
        }}
      >
        {children}
      </Box>
    </>
  );
}
export function StyledTitle({ children, ...props }) {
  return (
    <>
      <Box
        {...props}
        sx={{
          color: "#000000",
          fontSize: "40px",
          //   fontFamily: "Josefin Sans",
          fontWeight: "700",
          mr: props.mr,
        }}
      >
        {children}
      </Box>
    </>
  );
}

export function StyledGraphBox({ title, color }) {
  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography
          variant="h5"
          sx={{ color: `${color} !important`, lineHeight: "18px" }}
        >
          {title}
        </Typography>

        <ArrowForwardIosIcon
          sx={{ fontSize: "15px", color: "#ffff", ml: "5px" }}
        />
      </Box>
      ;
    </>
  );
}

export function StyledGraphNumberBox({
  oneDayBurn,
  sevenDayBurn,
  thirtyDayData,
}) {
  return (
    <Box display="flex">
      <Typography
        variant="h5"
        sx={{
          fontSize: "10px !important",
          textAlign: "center",
          px: "10px",
          borderRight: "1px solid #4B4F6D",
        }}
      >
        BURN LAST 24H
        <br />
        <span style={{ fontSize: "15px", color: "#fff" }}>
          {oneDayBurn ?? 0}
        </span>
      </Typography>
      <Typography
        variant="h5"
        sx={{
          fontSize: "10px !important",
          textAlign: "center",
          px: "10px",
          // pr: "10px",
          borderRight: "1px solid #4B4F6D",
        }}
      >
        BURN LAST 7 DAYS
        <br />
        <span style={{ fontSize: "15px", color: "#fff" }}>
          {sevenDayBurn ?? 0}
        </span>
      </Typography>
      <Typography
        variant="h5"
        sx={{
          fontSize: "10px !important",
          textAlign: "center",
          pl: "10px",
        }}
      >
        BURN LAST 30 DAYS
        <br />
        <span style={{ fontSize: "15px", color: "#fff" }}>
          {thirtyDayData ?? 0}
        </span>
      </Typography>
    </Box>
  );
}
