import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { theme } from "./theme";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { mainnet, bsc } from "wagmi/chains";
import { ConectivityProvider } from "./utils";
import { BrowserRouter } from "react-router-dom";

const chains = [mainnet, bsc];
const projectId = "cba73ada547c01c1a64d7725fb732495";
const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, version: 1, chains }),
  publicClient,
});
const ethereumClient = new EthereumClient(wagmiConfig, chains);
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <WagmiConfig config={wagmiConfig}>
      <Web3Modal
        themeMode="dark"
        projectId={projectId}
        ethereumClient={ethereumClient}
      />

      <ConectivityProvider>
        <CssBaseline />
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ThemeProvider>
      </ConectivityProvider>
    </WagmiConfig>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
